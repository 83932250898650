import { configureStore } from "@reduxjs/toolkit";
import patientOperationsReducer from "../features/patients/patientOperationsSlice";
import { patientOperationsApi } from "../services/api/PatientOperations/patientOperations";

export const store = configureStore({
  reducer: {
    patientOperations: patientOperationsReducer,

    [patientOperationsApi.reducerPath]: patientOperationsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(patientOperationsApi.middleware),
});
