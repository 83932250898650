import { axiosBaseQuery } from "../ReduxBaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const patientOperationsApi = createApi({
  reducerPath: "patientOperationsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Operations", "surgeries"],
  endpoints: (builder) => ({
    getOperations: builder.query({
      query: (patient) => ({
        url: `/patients/${patient.id}/operations`,
        method: "get",
      }),
      providesTags: ["Operations"],
    }),
    deleteOperation: builder.mutation({
      query: ({ patient, selected }) => ({
        url: `/patients/${patient.id}/operations/${selected.id}`,
        method: "delete",
      }),
      invalidatesTags: ["Operations"],
    }),
    getAllSurgeries: builder.query({
      query: () => ({
        url: `/surgeries`,
        method: "get",
      }),
      providesTags: ["surgeries"],
    }),
    AddNewSurgery: builder.mutation({
      query: (id, data) => ({
        url: `/surgeries/${id}`,
        method: "post",
        body: data,
        headers: {},
      }),
      invalidatesTags: ["surgeries"],
    }),
  }),
});

export const { useGetOperationsQuery, useDeleteOperationMutation } =
  patientOperationsApi;
