import axios from "axios";

const baseUrl = process.env.API_URL;

const axiosInstance = axios.create({
  baseURL: baseUrl,
  timeout: 120000,
  headers: {
    "Content-Type": "application/json",
  },
});

const axiosAuthorized = axios.create({
  baseURL: baseUrl,
  timeout: 120000,
  headers: {
    "Content-Type": "application/json",
  },
});

const token = localStorage.getItem("token");

axiosAuthorized.defaults.headers.common["Authorization"] = `Bearer ${token}`;
axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

export default { axiosInstance, axiosAuthorized };
