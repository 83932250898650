import { createSlice } from "@reduxjs/toolkit";

const inital = {
  selected: {
    id: 0,
    surgery_id: null,
    surgery_name: "",
    assistant: "",
    anesthetist: "",
    anesthesia: "",
    summary: "",
    diagnosis_id: null,
    diagnosis_name: "",
    date: "",
    cost: "",
    date_operation: null,
    finished_date: null,
  },
};

const patientOperationsSlice = createSlice({
  name: "patientOperations",
  initialState: inital,
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload;
    },
  },
});

export const { setSelected } = patientOperationsSlice.actions;

export default patientOperationsSlice.reducer;
