import { authorized } from "../../lib";

export const axiosBaseQuery =
  () =>
  async ({ url, method, body, params }) => {
    try {
      const res = await authorized({
        url,
        method,
        data: body,
        params,
      });
      return { data: res.data };
    } catch (axiosError) {
      let err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
