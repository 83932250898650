import React, { lazy, Suspense } from "react";
import { HashRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import "@popperjs/core";
import "./assets/sass/styles.scss";
import "react-toastify/dist/ReactToastify.css";
import "tippy.js/dist/tippy.css";
import { Provider } from "react-redux";
import { store } from "./store";
import Loading from "@components/common/Loading";

const App = lazy(() => import("./components/App"));

if (process.env.WEB) {
  // For web build
  const render = require("react-dom").render;

  // Render the app using ReactDOM.render()
  render(
    <HashRouter>
      <Provider store={store}>
        <Suspense
          fallback={
            <Loading
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            />
          }
        >
          <App />
        </Suspense>
      </Provider>
    </HashRouter>,
    document.getElementById("app")
  );
} else {
  // For Electron build
  const createRoot = require("react-dom/client").createRoot;

  // Create a new div element and append it to the body
  const root = document.createElement("div");
  root.id = "root";
  document.body.appendChild(root);

  // Create a root for the app using createRoot()
  const rootInstance = createRoot(document.getElementById("root"));

  // Render the app using root.render()
  rootInstance.render(
    <HashRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </HashRouter>
  );
}

// const Router = () => {
//   if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
//     return (
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     );
//   } else {
//     return (
//       <HashRouter>
//         <App />
//       </HashRouter>
//     );
//   }
// };
// Now we can render our application into it
