import React, { useState, useEffect } from "react";

const Loading = ({ style }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateProgress = (progress) => {
      if (progress <= 100) {
        setProgress(progress);
        setTimeout(() => {
          updateProgress(progress + 10);
        }, 500);
      }
    };

    const timeoutId = setTimeout(() => updateProgress(0), 0);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div style={style}>
      <div className="page-loading">
        <div className="loading">
          <div className="circle cyan"></div>
          <div className="circle magenta"></div>
          <div className="circle yellow"></div>
          <p>Loading</p>
        </div>

        <div
          style={{
            height: "30px",
            width: "250px",
            borderRadius: "5px",
            marginTop: "10px",
            zIndex: "1",
          }}
        >
          <div
            style={{
              position: "relative",
              height: "100%",
              backgroundColor: "#007bff",
              borderRadius: "5px",
              transition: "width 0.5s ease-in-out",
              width: `${progress}%`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              color: "#fff",
            }}
          >
            <span
              style={{
                position: "absolute",
                left: `${progress >= 0 && progress <= 20 ? "5px" : ""}`,
              }}
            >
              {progress}%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
